import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { Link } from '../components/Link'

export const Head = () => (
    <>
        <title>Swampscott Public Library Website Redesign and Development | Orientier Proposal Hub</title>
        <meta
            name="description"
            content="Resources illuminating a proposal to craft a new website for the public library in Swampscott, Massachusetts."
        />
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero
        heading="Swampscott Public Library Website Redesign and Development"
        subheading="A Proposal by Orientier for the Town of Swampscott, Massachusetts"
    />
)

const IndexPage = () => (
    <PageLayout hero={hero}>
        <Box fontSize="md" maxWidth="container.md" mx="auto" p={8}>
            <Text>Welcome, and thanks for your interest. We hope the resources here will illuminate our proposal.</Text>
            <Text>
                We plan to transition this space into an interview supplement, discovery platform, and beta testing tool
                if the opportunity arises.
            </Text>
            <Text>
                First, we <Link destination="/library-site-review">reviewed</Link> a select number of other public
                library websites we thought might serve as useful references for this project.
            </Text>
        </Box>
    </PageLayout>
)

export default IndexPage
